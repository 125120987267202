import React, { useEffect } from 'react';
import Component from './Component';
import { useFunctions, useSource } from '../hooks';
import { isObject } from '../util';

export const List = ({
  children,
  className,
  columns,
  direction = 'vertical',
  filter,
  itemDataSrc,
  order,
  limit,
  selectable = false,
  src,
  ...props
}) => {
  const { id, itemData: data } = props;
  let { set } = useFunctions();
  let { value } = useSource(src, {
    data,
    filter,
    isCollection: true,
    order,
    limit,
  });
  let { value: element } = useSource(`@element.${id}`);
  let { value: selected, count } = element || {};
  value = Object.entries(value || []).map(([key, value]) => ({
    NO_ID_FIELD: key,
    id: key,
    ...value,
  }));
  children = Array.isArray(children) ? children : [children];
  const items = Array.isArray(value) ? value : [value];

  useEffect(() => {
    if (count === items.length) {
      return;
    }
    set({ what: `@element.${id}.count`, value: items?.length || 0 });
  }, [items.length]);

  if (!isObject(value)) {
    return <Component {...props} />;
  }

  const handleClick = ({ item: value, i: index }) => {
    const { NO_ID_FIELD: selected = index } = value;
    set({
      what: `@element.${id}`,
      value: { index, selected, value, count },
    });
  };

  let columnClass = '';
  if (columns) {
    columnClass = columns === 'auto' ? 'list-columns-auto' : 'list-columns';
    direction = 'vertical';
  }

  return (
    <Component
      {...props}
      className={`list direction-${direction} ${columnClass} ${className} overflow-auto`.trim()}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {items.map((item, i) => (
        <div
          key={`list-${id}-item-${item?.NO_ID_FIELD || i}`}
          className={`${selectable ? 'cursor-pointer' : ''}${
            selected?.index === i ? ' selected' : ''
          }`.trim()}
          onMouseDown={({ currentTarget }) => handleClick({ item, i })}
        >
          {children.map(({ props, ...child }) => {
            if (
              item.lastModified &&
              item.lastModifiedDate &&
              item.name &&
              item.size &&
              item.type
            ) {
              item = {
                lastModified: item.lastModified,
                lastModifiedDate: item.lastModifiedDate,
                name: item.name,
                size: item.size,
                type: item.type,
                url: URL.createObjectURL(item),
              };
            }
            const itemData = {
              ...(data || {}),
              ...item,
            };
            const itemDataSrc = props?.src ? item[props?.src] : item;
            return {
              ...child,
              props: { ...props, itemData, itemDataSrc, __listId__: id },
            };
          })}
        </div>
      ))}
    </Component>
  );
};

List.displayName = 'List';
export default List;
